import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@mui/material";
import CardLayout from "../../components/Layout/CardLayout";
import DollarIcon from "../../assets/Icons/DollarIcon";
import ErrorModal from "../../components/Layout/ErrorModal";
import MobileIcon from "../../assets/Icons/MobileIcon";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import TicketIcon from "../../assets/Icons/TicketIcon";
import cacheKeys from "../../constants/cache";
import { useQR } from "../../hooks/qr";
import { useState } from "react";
import { useWelcome } from "../../hooks/welcome";

const Welcome = () => {
  const WELCOME_ERROR =
    "An error occurred. Please try again or contact support if the problem persists.";
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [networkError, setNetworkError] = useState(false);
  const qr = searchParams.get("qr");

  useQR(qr);

  // Set cache - without this, the other pages will redirect to /error
  window.sessionStorage.setItem(cacheKeys.qrCode, qr);

  const {
    data,
    isFetching,
    error: welcomeError,
  } = useWelcome(
    qr,
    () => setNetworkError(true),
    () => window.location.replace(PathConstants.ERROR), // need to do this OUTSIDE of React to prevent render loop
    () => window.location.replace(PathConstants.SESSION_EXPIRED) // need to do this OUTSIDE of React to prevent render loop
  );

  const handleOnClick = (path) => {
    navigate(path);
  };

  if (data?.sessionToken) {
    window.sessionStorage.setItem("session_token", data.sessionToken);
  }

  return (
    <PageLayout
      title={data?.skin?.welcomeMessage}
      subTitle={data?.skin?.locationName}
      isLoading={isFetching}
      networkError={networkError}
      networkErrorMessage={WELCOME_ERROR}
      onCloseNetworkError={() => setNetworkError(false)}
    >
      <ErrorModal
        description={WELCOME_ERROR}
        open={!!welcomeError || (data && data.exists === false)}
        onClose={() => {
          navigate(PathConstants.ERROR);
        }}
      />

      {data?.skin?.showPrepaidOptions ? (
        <>
          <CardLayout
            title="Already Have a Pass?"
            description="Let's get it activated."
          >
            <Button
              size="large"
              variant="contained"
              onClick={() =>
                (window.location.href = "https://bp.mlb.com/MIL_Parking_Lot")
              }
              color={"primary"}
              style={{
                fontWeight: 700,
                width: "100%",
                height: "3rem",
                marginTop: "1rem",
              }}
              startIcon={<MobileIcon />}
              aria-label="Activate your digital parking pass"
            >
              Activate Digital Pass
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={() =>
                handleOnClick(`${PathConstants.CONTACT_INFO}?type=activate`)
              }
              color="primary"
              style={{
                fontWeight: 700,
                width: "100%",
                height: "3rem",
                marginTop: "1rem",
              }}
              startIcon={<TicketIcon />}
              aria-label="Activate your printed parking pass"
            >
              Activate Printed Pass
            </Button>
            <div className="dividerDark">
              <div
                className="border-neutral-light"
                style={{ margin: "1rem 1rem", fontWeight: 700 }}
              >
                or
              </div>
            </div>
            <h2
              style={{
                color: "var(--secondary-color)",
                fontWeight: 700,
                fontSize: "20px",
                marginBottom: ".5rem",
              }}
            >
              {"Need to Buy a Pass?"}
            </h2>
            <Button
              size="large"
              variant="contained"
              onClick={() =>
                handleOnClick(`${PathConstants.CONTACT_INFO}?type=purchase`)
              }
              color="primary"
              style={{
                fontWeight: 700,
                width: "100%",
                height: "3rem",
              }}
              startIcon={<DollarIcon />}
              aria-label="Purchase a parking pass"
            >
              Purchase a Parking Pass
            </Button>
          </CardLayout>
        </>
      ) : (
        <>
          <CardLayout
            title="Purchase Parking"
            description={
              <>
                Need a parking pass?
                <br />
                Click below to get started.
              </>
            }
          >
            <div className="mt-4">
              <Button
                size="large"
                variant="contained"
                onClick={() =>
                  handleOnClick(`${PathConstants.CONTACT_INFO}?type=purchase`)
                }
                color="primary"
                style={{ fontWeight: 700, width: "100%", height: "3rem" }}
                startIcon={<DollarIcon />}
              >
                Purchase a Parking Pass
              </Button>
            </div>
          </CardLayout>
        </>
      )}
    </PageLayout>
  );
};

export default Welcome;
