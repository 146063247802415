import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { postPlate, removePlateIFromLocalStorage } from "../../services/plate";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Alert from "../../assets/Icons/Alert";
import ArrowBack from "../../assets/Icons/ArrowBack";
import ArrowForward from "../../assets/Icons/ArrowForward";
import ErrorModal from "../../components/Layout/ErrorModal";
import FlagUs from "../../assets/Icons/FlagUs";
import Modal from "../../components/Layout/Modal";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import Phone from "../../assets/Icons/Phone";
import Radio from "../../components/Inputs/Radio";
import Receipt from "../../assets/Icons/Receipt";
import TextField from "../../components/Inputs/TextField";
import TrashCan from "../../assets/Icons/TrashCan";
import cacheKeys from "../../constants/cache";
import { normalizeLicensePlate } from "../../utils/formatLicensePlate";
import { normalizePhoneNumber } from "../../utils/formatPhone";
import { postReceipt } from "../../services/receipt";
import { useContactInfo } from "../../hooks/contact-info";
import { usePlateModal } from "../../hooks/plate-modal";
import validateEmail from "../../utils/validate-email";
import validatePhoneNumber from "../../utils/validate-phone";

const ContactInfo = () => {
  const [plateWhiteListed, setPlateWhiteListed] = useState(false);
  const [passAlreadyPurchased, setPassAlreadyPurchased] = useState(false);
  const [plateFetching, setPlateFetching] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [plateError, setPlateError] = useState(null);
  const [receiptIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [showPlateWarning, setShowPlateWarning] = useState(false);
  const [lastPlates, setLastPlates] = useState([]);
  const [lastEmail, setLastEmail] = useState(null);
  const [lastPhone, setLastPhone] = useState(null);
  const [emailWarning, setEmailWarning] = useState("");
  const [phoneWarning, setPhoneWarning] = useState("");

  const [tempPlate, setTempPlate] = useState("");
  const [showPlatesModal, setShowPlatesModal] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const { data: contactInfo } = useContactInfo();
  const { data: welcomeData } = useQuery({ queryKey: ["welcome"] });
  const { data: plateModalData } = usePlateModal();

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);
  const defaultRadioValue = "Enter a new plate";

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }
  }, [qrCodeCache]);

  useEffect(() => {
    if (!welcomeData?.skin) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [welcomeData, qrCodeCache]);

  useEffect(() => {
    if (!contactInfo.showEmail || contactInfo.email === "") return;
    setEmailWarning(
      validateEmail(contactInfo.email)
        ? ""
        : "Please enter a valid email address."
    );
  }, [contactInfo.showEmail, contactInfo.email]);

  useEffect(() => {
    if (contactInfo.showEmail || contactInfo.phone === "") return;
    setPhoneWarning(
      validatePhoneNumber(contactInfo.phone)
        ? ""
        : "Please enter a valid mobile phone number."
    );
  }, [contactInfo.showEmail, contactInfo.phone]);

  useEffect(() => {
    const possibleLastPlates =
      localStorage.getItem("lastPlates") ?? localStorage.getItem("last5Plates");

    if (possibleLastPlates?.length > 0) {
      let parsedPlates = JSON.parse(possibleLastPlates);
      if (parsedPlates.length > 3) parsedPlates = parsedPlates.splice(0, 3);
      setLastPlates(parsedPlates);
    } else setLastPlates([]);

    const possibleLastEmail = localStorage.getItem("lastEmail");
    if (possibleLastEmail?.length > 0) setLastEmail(possibleLastEmail);
    else setLastEmail(null);

    const possibleLastPhone = localStorage.getItem("lastPhone");
    if (possibleLastPhone?.length > 0) setLastPhone(possibleLastPhone);
    else setLastPhone(null);
  }, []);

  useEffect(() => {
    if (lastPlates?.length > 0 && !plateModalData.hasLoaded) {
      setShowPlatesModal(true);
      plateModalData.hasLoaded = true;
    }
  }, [lastPlates, plateModalData]);

  const plateMutation = useMutation({
    mutationKey: ["plate"],
    mutationFn: (fieldValue) => {
      return postPlate(fieldValue);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["plate"], data);

      if (contactInfo.email?.length > 0)
        localStorage.setItem("lastEmail", contactInfo.email);

      if (contactInfo.phone?.length > 0)
        localStorage.setItem("lastPhone", contactInfo.phone);
      if (data.alreadyPaid) {
        if (data.alreadyPaidData) setPassAlreadyPurchased(true);
        else setPlateWhiteListed(true);
      } else if (data.warning && !(type === "activate")) {
        navigate(PathConstants.LOT_MISMATCH);
      } else if (data.valid === false) {
        const plateError =
          "An error occurred submitting your plate. Please try again or contact support if the problem persists.";
        setPlateError(plateError);
      } else if (data.plateInConvenience === false) {
        setShowPlateWarning(true);
      } else {
        proceed();
      }
    },
    onError: () => {
      setNetworkError(true);
    },
    onSettled: () => {
      setPlateFetching(false);
    },
  });

  const proceed = () => {
    if (type === "activate") navigate(PathConstants.PREPAID_TICKET);
    else if (type === "purchase") navigate(PathConstants.PARKING_RATES);
  };

  const handleOnClick = () => {
    navigate(-1);
  };

  const handleOnClickContinue = async () => {
    setPlateFetching(true);
    plateMutation.mutate(contactInfo.licensePlate);
  };

  const handleOnChangePlate = (e) => {
    const contactInfo = queryClient.getQueryData(["contactInfo"]);
    const changedPlate =
      e.target.value === defaultRadioValue ? "" : e.target.value;
    const plate = changedPlate.substring(0, 18).toUpperCase();
    queryClient.setQueryData(["contactInfo"], {
      ...contactInfo,
      licensePlate: normalizeLicensePlate(plate),
    });
    if (changedPlate !== tempPlate) setTempPlate("");
  };

  const handleOnChangeEmail = (e) => {
    const contactInfo = queryClient.getQueryData(["contactInfo"]);
    queryClient.setQueryData(["contactInfo"], {
      ...contactInfo,
      email: e.target.value,
    });
  };

  const handleOnChangePhone = (e) => {
    const contactInfo = queryClient.getQueryData(["contactInfo"]);
    queryClient.setQueryData(["contactInfo"], {
      ...contactInfo,
      phone: normalizePhoneNumber(e.target.value),
    });
  };

  const handleOnToggleShowEmail = () => {
    const contactInfo = queryClient.getQueryData(["contactInfo"]);
    queryClient.setQueryData(["contactInfo"], {
      ...contactInfo,
      showEmail: !contactInfo.showEmail,
    });
  };

  const handleShowPlatesModal = () => {
    setShowPlatesModal(true);
  };

  const handleOnClickReceipt = async () => {
    try {
      setPlateFetching(true);
      const plateData = queryClient.getQueryData(["plate"]);
      const res = await postReceipt(plateData.alreadyPaidData.receiptID);
      queryClient.setQueryData(["receipt"], res);
      navigate(PathConstants.RECEIPT);
    } catch (error) {
      setPlateError(
        "An error occurred. Please try again later or contact support."
      );
    } finally {
      setPlateFetching(false);
    }
  };

  const checkIfValid = () => {
    return (
      contactInfo.licensePlate?.length >= 2 &&
      (contactInfo.showEmail
        ? validateEmail(contactInfo.email)
        : validatePhoneNumber(contactInfo.phone))
    );
  };

  const continueEnabled = checkIfValid();

  const getPlateHeight = () => {
    const length = contactInfo.licensePlate.length;
    if (length > 17) return "h6";
    if (length > 12) return "h5";
    if (length > 8) return "h4";
    if (length > 7) return "h3";
    return "h2";
  };

  const removePlate = (plate) => {
    const index = lastPlates.indexOf(plate);
    if (index > -1) lastPlates.splice(index, 1);
    setLastPlates([...lastPlates]);
    removePlateIFromLocalStorage(plate);
    if (plate === contactInfo.licensePlate)
      handleOnChangePlate({ target: { value: "" } });
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title="Please Provide Your Information"
      withCancel
      showHelp={showHelp}
      onCloseHelp={() => setShowHelp(false)}
      isLoading={plateFetching || receiptIsLoading}
      networkError={networkError}
      networkErrorMessage="An error occurred submitting your plate. Please try again or contact support if the problem persists."
      onCloseNetworkError={() => {
        setNetworkError(false);
      }}
    >
      <ErrorModal
        open={!!plateError}
        onClose={() => setPlateError(null)}
        description={plateError}
      />
      <Modal
        open={!!passAlreadyPurchased && !plateError}
        withCancel
        onClose={() => setPassAlreadyPurchased(false)} //setPassAlreadyPurchased(false)}  may need to do more logic
      >
        <div className="text-secondary">
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">
                Pass Already Purchased
              </div>
              <div className="text-center mt-4">
                A parking pass for this vehicle has already been purchased for
                today&apos;s event.
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div style={{ marginLeft: "-.75rem", marginRight: "-.75rem" }}>
                <div className="mt-4 flex">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setShowHelp(true)}
                    color="primary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      marginRight: "1rem",
                      width: "45%",
                    }}
                    startIcon={<Phone />}
                  >
                    Need Help?
                  </Button>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => handleOnClickReceipt()}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      flexGrow: 1,
                      height: "2.5rem",
                      width: "45%",
                    }}
                    disabled={false}
                    startIcon={<Receipt fill="var(--neutral-white-color)" />}
                  >
                    View Receipt
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <Modal
        open={!!plateWhiteListed && !plateError}
        onClose={() => setPlateWhiteListed(false)}
      >
        <div className="text-secondary">
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">
                No Activation Required
              </div>
              <div className="text-center mt-4">
                No payment is required for this license plate.
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div style={{ marginLeft: "-.75rem", marginRight: "-.75rem" }}>
                <div className="mt-4 flex">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setPlateWhiteListed(false)}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      flexGrow: 1,
                      height: "2.5rem",
                      width: "45%",
                    }}
                    disabled={false}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <Modal
        open={!!showPlateWarning}
        onClose={() => setShowPlateWarning(false)}
      >
        <div className="text-secondary">
          <span className="mx-auto">
            <Alert />
            <div>
              <div className="text-center mt-4 font-bold text-lg">
                Confirm Plate
              </div>
              <div className="text-center mt-4">
                Please confirm your license plate is correct before proceeding:
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-.75rem", marginRight: "-.75rem" }}
              >
                <Paper
                  component={Stack}
                  direction="column"
                  justifyContent="center"
                  square={false}
                  sx={{ backgroundColor: "primary.main", height: "5rem" }}
                >
                  <Typography
                    align="center"
                    color="secondary"
                    variant={getPlateHeight()}
                  >
                    <Box component="span" fontWeight="fontWeightMedium">
                      {contactInfo.licensePlate}
                    </Box>
                  </Typography>
                </Paper>
              </div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div style={{ marginLeft: "-.75rem", marginRight: "-.75rem" }}>
                <div className="mt-4 flex">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={() => setShowPlateWarning(false)}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      marginRight: "1rem",
                      width: "45%",
                    }}
                    disabled={false}
                  >
                    Close
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => proceed()}
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      flexGrow: 1,
                      width: "45%",
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      <Modal
        open={showPlatesModal && !plateError}
        withClose
        onClose={() => {
          if (contactInfo?.licensePlate !== tempPlate) setTempPlate("");
          setShowPlatesModal(false);
        }}
      >
        <div className="text-secondary">
          <span className="mx-auto">
            <div>
              <div className="text-left mt-4 font-bold text-lg">
                Use Previous Plate?
              </div>
              <div className="text-left mt-4">
                Do you want to activate parking for any of these license plates?
              </div>
              <div className="text-left mt-4 font-bold">License Plate No.</div>
              <div className="mt-4">
                {lastPlates?.map((lp) => {
                  return (
                    <Radio
                      key={lp}
                      label={lp}
                      value={lp}
                      onChange={() => setTempPlate(lp)}
                      checked={lp === tempPlate}
                      isModalDisplay={true}
                      altAction={
                        <div
                          className="flex items-center inline-block mr-3"
                          onClick={() => removePlate(lp)}
                          style={{ cursor: "pointer" }}
                        >
                          <TrashCan />
                        </div>
                      }
                    ></Radio>
                  );
                })}
                <Radio
                  key={defaultRadioValue}
                  label={defaultRadioValue}
                  value={defaultRadioValue}
                  onChange={() => setTempPlate(defaultRadioValue)}
                  checked={defaultRadioValue === tempPlate}
                  isModalDisplay={true}
                ></Radio>
                <Divider />
              </div>
              <div className="mt-4 flex">
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => {
                    handleOnChangePlate({ target: { value: tempPlate } });
                    setShowPlatesModal(false);
                  }}
                  color="primary"
                  style={{
                    fontWeight: 700,
                    height: "2.5rem",
                    flexGrow: 1,
                    width: "45%",
                  }}
                  disabled={
                    lastPlates.indexOf(tempPlate) < 0 &&
                    tempPlate !== defaultRadioValue
                  }
                >
                  Continue
                </Button>
              </div>
            </div>
          </span>
        </div>
      </Modal>
      {lastPlates?.length > 0 ? (
        <span
          className="float-right align-middle"
          size="small"
          color="secondary"
          style={{
            fontSize: "14px",
            fontWeight: 500,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleShowPlatesModal()}
        >
          {"Use Previous Plate?"}
        </span>
      ) : null}
      <div className="my-5">
        <TextField
          id="license-plate"
          value={contactInfo.licensePlate}
          onChange={(e) => {
            handleOnChangePlate(e);
          }}
          label="License Plate"
          placeholder="ABC123"
        />
      </div>
      <span
        className="float-right align-middle"
        size="small"
        color="secondary"
        style={{
          fontSize: "14px",
          fontWeight: 500,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => handleOnToggleShowEmail()}
      >
        {contactInfo.showEmail ? "Text Message Instead?" : "Email Instead?"}
      </span>
      {contactInfo.showEmail ? (
        <>
          <div className="my-5">
            <TextField
              id="email"
              value={contactInfo.email}
              onChange={(e) => handleOnChangeEmail(e)}
              label="Email"
              placeholder={lastEmail ?? "john.doe@example.com"}
              error={emailWarning}
              type="email"
            />
          </div>
        </>
      ) : (
        <>
          <div className="my-5">
            <TextField
              id="phone"
              value={contactInfo.phone}
              type="tel"
              name="phone"
              autocomplete="mobile"
              onChange={(e) => handleOnChangePhone(e)}
              label="Phone Number"
              placeholder={lastPhone ?? "(123) 456-7890"}
              error={phoneWarning}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FlagUs />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </>
      )}
      <div className="text-left text-secondary leading-4 text-sm">
        Your contact information will only be used for communication related to
        this service.
      </div>
      <br />
      <div className="text-left text-secondary leading-4 text-sm font-bold">
        By continuing you agree to our{" "}
        <Link
          to={PathConstants.TERMS_OF_USE}
          className="underline text-secondary font-bold"
        >
          Terms of Use
        </Link>{" "}
        and{" "}
        <Link
          to={PathConstants.PRIVACY_POLICY}
          className="underline text-secondary font-bold"
        >
          Privacy Policy
        </Link>
      </div>
      <div className="mt-4">
        <Button
          size="large"
          variant="contained"
          onClick={() => handleOnClick()}
          color="secondary"
          style={{
            fontWeight: 700,
            width: "30%",
            height: "3rem",
            position: "inline-block",
            marginRight: "1rem",
          }}
          startIcon={<ArrowBack fill="var(--neutral-white-color)" />}
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => handleOnClickContinue()}
          color="primary"
          style={{ fontWeight: 700, width: "65%", height: "3rem" }}
          endIcon={
            <ArrowForward
              fill={
                !continueEnabled
                  ? "rgba(0, 0, 0, 0.26)"
                  : "var(--secondary-color)"
              }
            />
          }
          disabled={!continueEnabled}
        >
          Agree & Continue
        </Button>
      </div>
    </PageLayout>
  );
};

export default ContactInfo;
