import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

import router from "./routes/router";
import Theme from "./Theme";

import "./index.css";

const ENVIRONMENT = import.meta.env.MODE;
const isProd = ENVIRONMENT === 'production';

Sentry.init({
  dsn: "https://b450bdb53bc9427771ef4b5b3a1b6d93@o4507692276514816.ingest.us.sentry.io/4507692408438784",
  environment: ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: isProd ? 0.1 : 1.0, // 10% in production, 100% otherwise
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    // regext to match all subdomains that starts with  https://*.tapntailgate.com
    /^https:\/\/.*\.tapntailgate\.com/,
    // regext to match all subdomains that starts with  https://*.ipctg.com
    /^https:\/\/.*\.ipctg\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: isProd ? 0.1 : 1.0, // 10% in production, 100% otherwise
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Theme>
        <RouterProvider router={router} />
      </Theme>
    </QueryClientProvider>
  </React.StrictMode>
);
